<template>
    <modal ref="modalAceptarAfiliacion" titulo="Aceptar afiliación" tamano="modal-lg" no-aceptar :btns="botonesModal" @accion="accionesBotones">
        <div class="row mx-0">
            <div class="col-auto px-3" />
            <div class="col">
                <div v-show="buscar" class="row mx-0 align-items-center mb-2">
                    <div class="col-5 pl-0">
                        <el-input v-model="codigo" placeholder="Ingresar código de usuario" size="small" prefix-icon="icon-search" class="w-100 br-20" @keydown.native="detectarEnter($event)" />
                    </div>
                    <div class="btn-general px-4 ml-2 f-14" @click="buscarUsuario">
                        Buscar
                    </div>
                </div>
                <div class="row mx-0 align-items-center border bg-fondo br-12 py-2 px-2" style="height:49px;">
                    <!-- <img :src="img_url" class="obj-cover rounded-circle border" width="32" height="32" /> -->
                    <div class="col-auto text-general f-17 f-600">
                        {{ usuario.nombre }}
                    </div>
                    <div class="col-auto text-general f-17 f-600">
                        Código: {{ usuario.id }}
                    </div>
                    <div class="col-auto text-general-red f-17 f-600 ml-auto">
                        {{ usuario.nombre_empresa }}
                    </div>
                </div>
                <div class="row mx-0 py-3" />
                <ValidationObserver v-show="usuario.id" ref="form1">
                    <div class="row mx-0">
                        <div v-show="convenio.cedula_requerida != 0" class="col">
                            <p class="text-general f-12 pl-3"> Documento </p>
                            <ValidationProvider v-slot="{ errors }" :rules="{required:validador.cedula}" name="documento">
                                <el-input v-model="usuario.cedula" class="w-100" size="small" />
                                <span class="text-danger f-11">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col">
                            <p class="text-general f-12 pl-3"> Cupon de credito </p>
                            <ValidationProvider v-slot="{ errors }" :rules="{max_value:16000000}" name="cupo">
                                <el-input v-model="usuario.cupo" class="w-100" size="small" :disabled="checked" />
                                <span class="text-danger f-11">{{ errors[0] }}</span>
                            </ValidationProvider>
                            <el-checkbox v-model="checked" class="mt-2 check-dark ml-4" @change="checkIlimitado">
                                Ilimitado
                            </el-checkbox>
                        </div>
                        <div v-show="convenio.salario_requerido != 0" class="col">
                            <p class="text-general f-12 pl-3"> Salario </p>
                            <ValidationProvider v-slot="{ errors }" :rules="{required:validador.salario,max_value:16000000}" name="salario">
                                <el-input v-model="usuario.salario" class="w-100" size="small" />
                                <span class="text-danger f-11">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 my-3">
                        <div class="col">
                            <p class="text-general f-12 pl-3"> Fecha vinculacion con la empresa </p>
                            <ValidationProvider v-slot="{ errors }" rules="required" name="Fecha">
                                <el-date-picker
                                v-model="usuario.fecha_vinculacion"
                                type="date"
                                size="small"
                                placeholder="Fecha vinculacion"
                                :clearable="false"
                                format="dd MMMM yyyy"
                                value-format="yyyy-MM-dd"
                                />
                                <span class="text-danger f-11 d-block">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div v-show="convenio.documento_requerido != 0" class="row mx-0">
                        <div v-if="usuario.documento === null" class="col-12">
                            <el-upload
                            ref="adjunto"
                            class="upload-demo"
                            action="#"
                            :auto-upload="false"
                            :on-change="loadDocumento"
                            :on-remove="removeDocumento"
                            :limit="1"
                            >
                                <div class="btn-file br-12 text-general f-14">
                                    Subir "{{ convenio.documento_label }}"
                                    </br>
                                    ( {{ tipoDocumento.toString() }} )
                                </div>
                            </el-upload>
                        </div>
                        <div v-else class="col-12 mt-3">
                            <div class="row mx-0">
                                <div class="card-file d-middle">
                                    <div class="h-100 bg-gris d-middle-center" style="width:48px;border-radius:12px 0px 0px 12px;">
                                        <i class="icon-file-pdf f-17" />
                                    </div>
                                    <div class="col f-12 text-general tres-puntos">
                                        {{ convenio.documento_label }}
                                    </div>
                                </div>
                                <div class="btn-delete-file cr-pointer ml-2" @click="deleteFile">
                                    <i class="icon-delete-trash f-20" />
                                </div>
                                <div class="btn-download-file cr-pointer ml-2">
                                    <a :href="usuario.documento" download target="_blank" class="text-white">
                                        <i class="icon-dowload-arrow f-20" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
            <div class="col-auto px-3" />
        </div>
    </modal>
</template>
<script>
import Afiliados from '~/services/nomina/afiliados'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            buscar:true,
            checked: false,
            codigo: '',
            usuario:{},
            file:null,
            extencion:null,
            closeModal:true,
            fileRequire:false,
            removerFile:false,

            botones: [
                { texto: 'Afiliar', color: 'btn-general px-3 f-14', accion:1 },
                { texto: 'Afiliar y buscar otro', color: 'btn-general px-3 f-14', accion:2 },
                { texto: 'Actualizar', color: 'btn-general px-3 f-14', accion:3 }
            ],

            botonesModal:[]
        }
    },
    computed:{
        idConvenio(){
            return Number(this.$route.params.id_convenio)
        },
        ...mapGetters({
            convenio:'nomina/nomina/convenio'
        }),
        validador(){
            const valid = {
                cedula:this.convenio.cedula_requerida === 2,
                salario:this.convenio.salario_requerido === 2,
                documento:this.convenio.documento_requerido === 2
            }
            return valid
        },
        tipoDocumento(){
            switch (this.convenio.documento_tipo){
            case 1:
                return ['jpg','png','jpeg']
            case 2:
                return ['pdf']
            case 3:
                return ['jpg','png','jpeg','pdf']
            default:
                return ['jpg','png','jpeg','pdf']
            }
        }
    },
    methods: {
        toggle(){
            this.buscar = true
            this.botonesModal = []
            this.botonesModal.push(this.botones[0],this.botones[1])
            this.limpiar()
            this.codigo = ''
            this.$refs.modalAceptarAfiliacion.toggle();
        },
        detectarEnter(key){
            const code = key.keyCode
            if(code === 13){
                this.buscarUsuario()
            }
        },
        async buscarUsuario(){
            try {
                if(this.codigo === '') return
                this.limpiar()
                this.getUsuario()

            } catch (error){
                this.error_catch(error)
            }
        },
        async getUsuario(){
            try {

                const {data} = await Afiliados.buscarUsuario(this.codigo)
                this.usuario = data.usuario

                if(this.usuario.cupo === 0){
                    this.checked = true
                }

                if(this.usuario.id_convenio === null){
                    this.fileRequire = true

                }else if(this.usuario.documento === null){
                    this.fileRequire = true
                }else{
                    this.fileRequire = false
                }

            } catch (error){
                this.error_catch(error)
            }
        },
        async afiliarUserNomina(){
            try {

                let formulario = {
                    cedula:this.usuario.cedula,
                    salario:this.usuario.salario,
                    cupo:this.usuario.cupo,
                    id_user:this.usuario.id,
                    fecha_vinculacion:this.usuario.fecha_vinculacion,
                    documento_tipo:this.extencion,
                    file:this.file,
                    file_valid:Number(this.fileRequire),
                    delete_file:Number(this.removerFile)
                }

                const form = this.crear_formData(formulario)

                const {data} = await Afiliados.afiliarUserNomina(this.idConvenio, form)
                this.notificacion('','Usuario asociado al convenio correctamente','success')

                if(this.closeModal){
                    this.$refs.modalAceptarAfiliacion.toggle();
                }else{
                    this.limpiar()
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async accionesBotones(accion){
            switch (accion){
            case 1:
                this.closeModal = true
                break;
            case 2:
                this.closeModal = false
                break;
            }


            const valid = await this.$refs.form1.validate()

            if(!valid){
                this.notificacion('Advertencia','Por favor llene los campos requeridos','warning')
                return
            }

            if(this.validador.documento && this.file === null){
                this.notificacion('Advertencia','Por favor agregue un documento de soporte','warning')
                return
            }

            if(this.fileRequire && this.file === null){
                this.fileRequire = false
            }


            this.afiliarUserNomina()


        },
        async loadDocumento(file, fileList){

            const ext = file.name.split('.').pop()
            if (!this.tipoDocumento.includes(ext)){
                fileList.splice(fileList.indexOf(file), 1)
                this.notificacion('Advertencia', 'El archivo no cumple con la extensión requerida', 'warning')
                return
            }

            this.extencion = ext == 'pdf' ? 2 : 1
            this.fileRequire = true
            this.file = file.raw
        },
        async getSolicitud(idUser){
            this.botonesModal = []
            this.buscar = false
            this.codigo = idUser
            await this.getUsuario()
            this.botonesModal.push(this.botones[0])
            this.$refs.modalAceptarAfiliacion.toggle();
        },
        removeDocumento(){
            this.file = null
            this.extencion = null
            this.fileRequire = false
            this.$refs.adjunto.clearFiles()
        },
        deleteFile(){
            this.usuario.documento = null
            this.removerFile = true
        },
        checkIlimitado(check){
            if(check){
                this.usuario.cupo = 0
            }else{
                this.usuario.cupo = 100
            }

        },
        limpiar(){
            this.checked =  false
            this.usuario = {}
            this.file = null
            this.extencion = null
            this.closeModal = true
            this.fileRequire = false
            this.removerFile = false
        }
    }
}
</script>
<style lang="scss" scoped>
.btn-file{
    width: 302px;
    height: 50px;
    background-color: #F6F9FB;
    border: 1px dashed #637381;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-file{
    width: 312px;
    height: 40px;
    border-radius: 12px;
    box-shadow: 0px 2px 4px #00000014;
}

.btn-delete-file{
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background-color: #FF5158;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-download-file{
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background-color: #29D884;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
